// SemanticUI-free pre-@plone/components
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useSelector, shallowEqual } from 'react-redux';
import { readFromEnvVar } from '@package/helpers';
import config from '@plone/volto/registry';
import { getBaseUrl } from '@plone/volto/helpers';

const Anontools = () => {
  const { settings } = config;
  const token = useSelector((state) => state.userSession.token, shallowEqual);
  const content = useSelector((state) => state.content.data, shallowEqual);
  const pathname = useSelector(
    (state) => state.router.location.pathname,
    shallowEqual,
  );

  const prefixPath = readFromEnvVar('RAZZLE_PREFIX_PATH');
  const cameFrom = content?.['@id']
    ? `${prefixPath}/api${content['@id'].replace(settings.apiPath, '')}`
    : `${prefixPath}/api${getBaseUrl(pathname)}`;
  const loginUrl = `${prefixPath}/login?came_from=${cameFrom}`;

  return (
    !token && (
      <div className="anontools">
        <a aria-label="login" href={loginUrl}>
          <FormattedMessage id="Log in" defaultMessage="Log in" />
        </a>
      </div>
    )
  );
};

export default Anontools;

Anontools.propTypes = {
  token: PropTypes.string,
};

Anontools.defaultProps = {
  token: null,
};
