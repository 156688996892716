import React, { useState, useEffect } from 'react';
import { FormFieldWrapper, Icon } from '@plone/volto/components';
import { useSelector } from 'react-redux';
import clearSVG from '@plone/volto/icons/clear.svg';
import pencilSVG from '@plone/volto/icons/pencil.svg';
import { Api } from '@plone/volto/helpers';

const FooterMappingWidget = (props) => {
  const [newPath, setNewPath] = useState('');
  const [mappings, setMappings] = useState([]);

  const api = new Api();

  const { token } = useSelector((state) => state.userSession);
  const footerPath = 'footers';

  const id = props.id;
  const value = props.value;
  const onChange = props.onChange;

  // Initialize the table
  useEffect(() => {
    if (value) {
      setMappings(JSON.parse(value));
    }
  }, [value]);

  const createFooter = async () => {
    const contentId =
      Date.now().toString(36) + Math.random().toString(36).substr(2);

    const response = await api.post(`/${footerPath}`, {
      data: {
        '@type': 'Footer',
        id: contentId,
        title: 'Footer',
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.UID;
  };

  const handleAddMapping = async (event) => {
    event.preventDefault();

    // Check for duplicates and empty path
    if (
      newPath.trim() === '' ||
      mappings.some((mapping) => mapping.path === newPath)
    ) {
      return;
    }

    // Add the new mapping
    const contentUid = await createFooter();
    const newMapping = { path: newPath, uid: contentUid };
    const updatedMappings = [...mappings, newMapping];
    setMappings(updatedMappings);
    setNewPath(''); // Clear the input after adding

    // If there's an onChange handler, call it with the new mappings as a JSON string
    if (onChange) {
      onChange(id, JSON.stringify(updatedMappings));
    }
  };

  const handleEditMapping = async (event, uid) => {
    event.preventDefault();

    const response = await api.get(`/@search?UID=${uid}`, {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    console.log(response);
    const url = `${response['items'][0]['@id']}/edit`;

    window.open(url, '_blank');
  };

  const handleDeleteMapping = async (event, index) => {
    event.preventDefault();

    const updatedMappings = mappings.filter((_, idx) => idx !== index);
    setMappings(updatedMappings);

    // If there's an onChange handler, call it with the new mappings as a JSON string
    if (onChange) {
      onChange(id, JSON.stringify(updatedMappings));
    }
  };

  return (
    <FormFieldWrapper
      {...props}
      className="editable-footer-mapping"
      description="Here you can map paths to Footer content objects. Remember to save this page too!"
    >
      <div className="footer-mapping-widget">
        <div className="row footer-add-row">
          <label className="input-label" htmlFor="addInput">
            New Path
          </label>
          <input
            id="addInput"
            className="input-field"
            type="text"
            value={newPath}
            onChange={(e) => setNewPath(e.target.value)}
          />
          <button className="add-button" onClick={handleAddMapping}>
            Add
          </button>
        </div>
        <div className="row footer-mappings">
          {mappings.length > 0 ? (
            mappings.map((mapping, idx) => (
              <div key={mapping.uid} className="mapping-group">
                <label className="input-label" htmlFor="pathInput">
                  Path
                </label>
                <input
                  className="input-field read-only"
                  id="pathInput"
                  readOnly
                  value={mapping.path}
                />
                <label className="input-label" htmlFor="uidInput">
                  UID
                </label>
                <input
                  id="uidInput"
                  className="input-field read-only"
                  readOnly
                  value={mapping.uid}
                />
                <button
                  className="edit-button"
                  onClick={(event) => handleEditMapping(event, mapping.uid)}
                >
                  <Icon name={pencilSVG} size="30px" />
                </button>
                <button
                  className="delete-button"
                  onClick={(event) => handleDeleteMapping(event, idx)}
                >
                  <Icon name={clearSVG} size="30px" />
                </button>
              </div>
            ))
          ) : (
            <p>No footer mappings found. Add a new mapping.</p>
          )}
        </div>
      </div>
    </FormFieldWrapper>
  );
};
export default FooterMappingWidget;
