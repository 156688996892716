/**
 * Add your helpers here.
 * @module helpers
 * @example
 * export { Api } from './Api/Api';
 */
const readFromEnvVar = (varName) => {
  if (typeof process !== 'undefined' && process.env?.[varName]) {
    return process.env[varName];
  } else if (typeof window !== 'undefined' && window.env?.[varName]) {
    return window.env[varName];
  }
  return '';
};
export { readFromEnvVar };
