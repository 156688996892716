import { BodyClassLightThemeExtension } from 'volto-jyu-light-theme-addon/components';
import { JYUThemeFooter } from 'volto-jyu-light-theme-addon/components';

import '@plone/volto/config';

const applyConfig = (config) => {
  (config.settings.footerFragments =
    config.settings.footerFragments || []).push(JYUThemeFooter);

  config.settings.appExtras = [
    ...config.settings.appExtras,
    {
      match: '',
      component: BodyClassLightThemeExtension,
    },
  ];
  return config;
};

export default applyConfig;
