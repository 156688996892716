import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import config from '@plone/volto/registry';

import { SET_LIGHTSITE_SETTINGS } from '@package/constants/ActionTypes';

const setLightsiteSettings = (settings) => ({
  type: SET_LIGHTSITE_SETTINGS,
  payload: settings,
});

const LightsiteSettings = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch the jyu-light-theme-enabled setting from the Plone registry.
        const response = await fetch(
          `${config.settings.publicURL}/++api++/@lightsite-settings-data`,
        );
        const data = await response.json();

        // Also, store the result to redux store.
        dispatch(setLightsiteSettings(data));
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error fetching registry:', error);
      }
    };
    fetchData();
  }, [dispatch]);

  return null;
};

export default LightsiteSettings;
