import React, { useEffect } from 'react';
import { readFromEnvVar } from '@package/helpers';

const CustomCSS = () => {
  var customCssPath = readFromEnvVar('RAZZLE_CUSTOM_CSS_PATH');

  useEffect(() => {
    const link = document.createElement('link');
    link.href = customCssPath;
    link.rel = 'stylesheet';
    document.head.appendChild(link);
    return () => {
      document.head.removeChild(link);
    };
  }, [customCssPath]);

  return <></>;
};

export default CustomCSS;
