/**
 * Root reducer.
 * @module reducers/root
 */

import { SET_LIGHTSITE_SETTINGS } from '@package/constants/ActionTypes';

import defaultReducers from '@plone/volto/reducers';

const lightsite = (state = {}, action = {}) => {
  switch (action.type) {
    case SET_LIGHTSITE_SETTINGS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

/**
 * Root reducer.
 * @function
 * @param {Object} state Current state.
 * @param {Object} action Action to be handled.
 * @returns {Object} New state.
 */
const reducers = {
  ...defaultReducers,
  lightsite,
};

export default reducers;
