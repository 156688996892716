import React, { useEffect } from 'react';
import { readFromEnvVar } from '@package/helpers';

const CustomJS = () => {
  var customJsPath = readFromEnvVar('RAZZLE_CUSTOM_JS_PATH');

  useEffect(() => {
    const script = document.createElement('script');
    script.src = customJsPath;
    script.async = true;
    document.head.appendChild(script);
    return () => {
      document.head.removeChild(script);
    };
  }, [customJsPath]);

  return <></>;
};

export default CustomJS;
