import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { BodyClass } from '@plone/volto/helpers';

const BodyClassExtension = () => {
  const [className, setClassName] = useState('');
  const themeEnabled = useSelector(
    (state) => state?.lightsite?.jyu_light_theme_enabled,
  );

  useEffect(() => {
    setClassName(
      themeEnabled ? 'jyu-light-theme-enabled' : 'jyu-light-theme-not-enabled',
    );
  }, [themeEnabled]);

  // Render the BodyClass component with the dynamic className.
  // This will add the class to the body element of the document.
  return <BodyClass className={className} />;
};

export default BodyClassExtension;
