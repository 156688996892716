import { useEffect } from 'react';
import { useSelector } from 'react-redux';

// Helper function to determine if the color is light or dark
const isColorLight = (color) => {
  // Check if the color is a valid hex code
  const validColorRegex = /^#(?:[0-9a-fA-F]{3}){1,2}$/;
  if (!validColorRegex.test(color)) {
    console.error(`Invalid color code: ${color}`);
    return false; // Default to dark text if color is invalid
  }

  let r, g, b;
  if (color.length === 4) {
    // Expand shorthand hex code to full hex code
    r = parseInt(color[1] + color[1], 16);
    g = parseInt(color[2] + color[2], 16);
    b = parseInt(color[3] + color[3], 16);
  } else {
    r = parseInt(color.slice(1, 3), 16);
    g = parseInt(color.slice(3, 5), 16);
    b = parseInt(color.slice(5, 7), 16);
  }

  // Calculate luminance according to the WCAG guidelines
  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
  return luminance > 0.5;
};

const GlobalColorVariables = () => {
  const mainColor1 = useSelector((state) => state?.lightsite?.main_color_1);
  const mainColor2 = useSelector((state) => state?.lightsite?.main_color_2);
  const mainColor3 = useSelector((state) => state?.lightsite?.main_color_3);

  useEffect(() => {
    const root = document.documentElement;

    if (mainColor1) {
      root.style.setProperty('--lightsite-main-color-1', mainColor1);
      const textColor1 = isColorLight(mainColor1) ? '#000000' : '#ffffff';
      root.style.setProperty('--lightsite-main-color-text-1', textColor1);
    }

    if (mainColor2) {
      root.style.setProperty('--lightsite-main-color-2', mainColor2);
      const textColor2 = isColorLight(mainColor2) ? '#000000' : '#ffffff';
      root.style.setProperty('--lightsite-main-color-text-2', textColor2);
    }
    if (mainColor3) {
      root.style.setProperty('--lightsite-main-color-3', mainColor3);
      const textColor3 = isColorLight(mainColor3) ? '#000000' : '#ffffff';
      root.style.setProperty('--lightsite-main-color-text-3', textColor3);
    }
  }, [mainColor1, mainColor2, mainColor3]);

  return null;
};

export default GlobalColorVariables;
